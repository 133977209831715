$MochiyFont: 'Mochiy Pop One', sans-serif;

/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgb(126, 126, 126) #494949;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #494949;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(126, 126, 126);
  // border-radius: 20px;
  // border: 3px solid #333;
}

h1 {
  font-family: $MochiyFont;
  font-size: clamp(3rem, 5vw, 5rem);
}

a {
  text-decoration: none;
  color: #f2f2f2;
  display: inline-block;
}

.container {
  margin-inline: 10%;
}
