h1 {
  text-align: center;
  padding-block: 2rem;
  color: #f2f2f2;
  text-transform: uppercase;
}

.builds_grid {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  width: 100%;
  place-items: center;

  @media screen and (min-width: 750px) {
    place-items: unset;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

.build_container {
  position: relative;
  width: 300px;
  display: flex;
  flex-direction: column;
  background-color: #333;
  padding-block: 2rem;
  padding-inline: 5rem;
  border-radius: 5px;
  border: 3px solid #f2f2f2;
  color: #f2f2f2;

  @media screen and (min-width: 750px) {
    width: 400px;
  }

  .icons_group {
    display: flex;
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 1.2rem;
    gap: 0.2rem;

    svg {
      cursor: pointer;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-position: inside;
  }
}

h5 {
  margin-bottom: 2rem;
}

h3 > span {
  font-weight: 400;
}
