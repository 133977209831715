.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border-radius: 5px;
  border: 2px #f2f2f2 solid;
  font-weight: 700;
  cursor: pointer;
  color: #333;
  background-color: #f2f2f2;
  width: fit-content;
  transition: all 300ms ease;
  cursor: pointer;

  &:is(:hover, :focus) {
    background-color: #333;
    color: #f2f2f2;
  }
}
