.card {
  height: 400px;
  width: 300px;
  border-radius: 5px;
  display: flex;
  color: #f2f2f2;
  position: relative;

  &::before {
    content: '';
    inset: 0;
    position: absolute;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.37298669467787116) 60%, rgba(0, 0, 0, 0.8267682072829132) 100%);
    border-radius: 0 0 5px 5px;
  }

  .card-content {
    align-self: flex-end;
    margin-inline: 1rem;
    margin-block: 2rem;
    width: 100%;
    z-index: 1;
  }
  .skill-row {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  .card-skill {
    height: 40px;
    width: 40px;
    box-shadow: 0px 0px 3px #f2f2f2;
  }

  .flex {
    display: flex;
  }

  .icon {
    width: 25px;
    height: 25px;
  }
}
