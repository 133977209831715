.no_result_container {
  display: grid;
  place-content: center;
  align-items: center;
  gap: 2rem;
  height: 100vh;
  position: relative;
  isolation: isolate;

  &::before {
    content: '';
    background-color: rgba(43, 43, 43, 0.836);
    position: absolute;
    inset: 0;
    z-index: -1;
  }
}
