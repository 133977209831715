.layout {
  min-height: 100vh;
  position: relative;
  isolation: isolate;

  &::before {
    content: '';
    background-color: rgba(43, 43, 43, 0.85);
    position: absolute;
    inset: 0;
    z-index: -1;
  }
}

.outlet_section {
  padding-block: 3rem;

  @media screen and (min-width: 900px) {
    padding-block: 5rem;
  }
}
