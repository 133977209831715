$full-bleed: rgba(56, 56, 56, 0.5);
$MochiyFont: 'Mochiy Pop One', sans-serif;
$navHeight: 50px;

nav {
  font-family: $MochiyFont;
  background-color: $full-bleed;
  position: relative;
  // position: fixed;
  width: 100%;
  z-index: 20;
  transition: all 300ms ease-in;

  .nav_bar {
    height: $navHeight;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media screen and (min-width: 900px) {
      height: unset;
    }

    .nav-menu {
      margin-left: auto;
      color: #f2f2f2;
      cursor: pointer;
      display: flex;
      font-size: 3rem;

      @media screen and (min-width: 900px) {
        display: none;
      }
    }
    ul.ul_menu {
      position: absolute;
      background-color: rgba(56, 56, 56);
      top: $navHeight;
      right: 0;
      width: 100vw;
      height: calc(100vh - $navHeight);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      list-style: none;
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      transform: translateX(-100%);
      -webkit-transition: all 300ms 300ms ease;
      -o-transition: all 300ms 300ms ease;
      transition: all 300ms 300ms ease;
      padding-top: 2rem;
      gap: 2rem;
      z-index: 20;

      @media screen and (min-width: 900px) {
        background-color: unset;
        position: unset;
        height: unset;
        top: 0;
        padding-top: unset;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
      }

      &.visible {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
      }

      .nav_link {
        color: #f2f2f2;
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        text-transform: uppercase;
        cursor: pointer;
        padding-block: 1rem;
        padding-inline: 1rem;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        -webkit-transition-property: background-color;
        -o-transition-property: background-color;
        transition-property: background-color;
        -webkit-transition-duration: 200ms;
        -o-transition-duration: 200ms;
        transition-duration: 200ms;

        &:is(:focus, :hover) {
          color: rgba(56, 56, 56);
          background-color: #f2f2f2;
        }

        @media screen and (min-width: 900px) {
          width: unset;
        }
      }
    }
  }
}

.scrollFixed {
  position: fixed;
}
