h1 {
  text-align: center;
  padding-block: 2rem;
  color: #f2f2f2;
  text-transform: uppercase;
}

.items_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  width: 100%;
  place-items: center;

  @media screen and (min-width: 750px) {
    place-items: unset;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

.item-card {
  color: #f2f2f2;
  background-color: rgb(44, 44, 44);
  border-radius: 5px;
  border: solid #f2f2f2 1px;
  padding-block: 1rem;
  padding-inline: 1rem;
  overflow-y: auto;
  width: 300px;

  @media screen and (min-width: 750px) {
    width: 350px;
    height: 250px;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    h2 {
      border-bottom: 1px solid #f2f2f2;
      width: 100%;
    }
    img {
      height: 30px;
      width: 30px;
    }
  }

  .inside-content {
    p > span:not(:last-child)::after {
      content: '|';
    }
    p:nth-child(2) {
      margin-top: 1rem;
    }
    .strong {
      font-weight: 700;
    }
  }
}
