.new_build_container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 2rem;

  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
}

.form_new_build {
  display: flex;
  flex-direction: column;
  background-color: #333;
  padding-block: 2rem;
  padding-inline: 1rem;
  border-radius: 5px;
  border: 3px solid #f2f2f2;
  color: #f2f2f2;

  @media screen and (min-width: 900px) {
    width: 50%;
  }

  .form_new_build_section {
    margin-bottom: 1.5rem;
    display: flex;
    gap: 1rem;
  }

  svg {
    font-size: 1rem;
  }

  h3 {
    margin-bottom: 3rem;
    color: #f2f2f2;
  }

  h4 {
    color: #f2f2f2;
    font-weight: 500;
    font-size: 1.5rem;

    & + h4 {
      margin-top: 1rem;
    }

    & > span {
      font-weight: 300;
      text-decoration: underline;
    }
  }
}

.single_button {
  display: flex;

  justify-content: flex-end;
  margin-top: auto;
  height: 43px;
}

.buttons_group {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;

  & button:last-child {
    margin-left: 0.5rem;
  }
}

.container_item_box {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.item_box {
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  padding-inline: 0.5rem;
  padding-block: 0.5rem;
  margin-top: 0.5rem;
  width: fit-content;
  display: flex;
  align-items: center;
  color: #f2f2f2;
  white-space: nowrap;

  & > svg {
    cursor: pointer;
    margin-left: 0.5rem;
  }
}

.green {
  background-color: green;
}

.yellow {
  background-color: rgb(206, 110, 0);
}

.red {
  background-color: rgb(146, 0, 0);
}

.blue {
  background-color: rgb(0, 0, 170);
}
