@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&family=Roboto+Mono:wght@400;500;700&display=swap');
//font-family: 'Mochiy Pop One', sans-serif;
//font-family: 'Roboto Mono', monospace;
$MochiyFont: 'Mochiy Pop One', sans-serif;
$RobotoFont: 'Roboto Mono', monospace;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: $RobotoFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: $RobotoFont;
}
