h1 {
  text-align: center;
  padding-block: 2rem;
  color: #f2f2f2;
  text-transform: uppercase;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media screen and (min-width: 750px) {
    flex-direction: row;
    align-items: unset;
    margin-top: 2rem;
  }
  .filter-box-container {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    border: 1px #f2f2f2 solid;
    border-radius: 5px;
    padding-inline: 0.5rem;
    padding-block: 1rem;
    width: 300px;
    height: fit-content;
    color: #f2f2f2;

    @media screen and (min-width: 750px) {
      position: sticky;
      top: 10%;
    }
  }
  .heroes_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 3rem;
    width: 100%;
    place-items: center;

    @media screen and (min-width: 750px) {
      place-items: unset;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
  }
}
